<template>
  <div class="user-is-logged" v-if="_g_IsUserLogged">
    <div class="buttons-container">
      <Button v-if="active_button == 'contenidoreciente'" class="active" id="contenido-reciente"
        :button_name="button_name" @_emit_method_button="_getIndexTab('contenidoreciente')"
        :button_key="button_key_recent" :button_icon_permission="false"
        />

      <Button id="foro" v-if="active_button == 'contenidoreciente'" @_emit_method_button="_getIndexTab('foro')"
        :button_name="button_name2" :button_key="button_key_forum"
        :button_icon_permission="false" />

      <Button id="search" v-if="active_button == 'contenidoreciente'" @_emit_method_button="_getIndexTab('search')"
        :button_name="button_name3" :button_key="button_key_search"
        :button_icon_permission="false" />

      <!---->

      <Button v-if="active_button == 'foro'" id="contenido-reciente"
        @_emit_method_button="_getIndexTab('contenidoreciente')" :button_name="button_name"
        :button_key="button_key_recent" :button_icon_permission="false"
        />

      <Button id="foro" v-if="active_button == 'foro'" class="active" :button_name="button_name2"
        :button_key="button_key_forum" @_emit_method_button="_getIndexTab('foro')"
        :button_icon_permission="false" />

      <Button id="search" v-if="active_button == 'foro'" @_emit_method_button="_getIndexTab('search')"
        :button_name="button_name3" :button_key="button_key_search"
        :button_icon_permission="false" />

      <!---->

      <Button v-if="active_button == 'search'" id="contenido-reciente"
        @_emit_method_button="_getIndexTab('contenidoreciente')" :button_name="button_name"
        :button_key="button_key_recent" :button_icon_permission="false"
        />

      <Button id="foro" v-if="active_button == 'search'" @_emit_method_button="_getIndexTab('foro')"
        :button_name="button_name2" :button_key="button_key_forum"
        :button_icon_permission="false" />

      <Button id="search" v-if="active_button == 'search'" class="active" :button_name="button_name3"
        :button_key="button_key_search" @_emit_method_button="_getIndexTab('search')"
        :button_icon_permission="false" />

    </div>
    <LoadingForum v-if="isLoading" ref="loader" @loaded="finishLoading" :message="loadingMessage" />
    <div v-if="_g_UserFeatures.permissions.read_forum" class="main-container"
      :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
      <div v-if="tab_indexId == 'foro'" style="height: 90%">
        <iframe ref="iframe" @load="_forumIframeUpdated()" name="foro" id="foro-id" style="overflow:hidden" width="100%"
          height="100%" frameBorder="0"></iframe>
      </div>
      <div v-if="tab_indexId == 'contenidoreciente'" style="height: 90%">
        <iframe ref="iframe" @load="_contenidoIframeUpdated()" name="contenido" id="contenido-id"
          style="overflow:hidden" width="100%" height="100%" frameBorder="0"></iframe>
      </div>
      <div v-if="tab_indexId == 'search'" style="height: 90%">
        <iframe ref="iframe" @load="_searchIframeUpdated()" name="contenido" id="search-id" style="overflow:hidden"
          width="100%" height="100%" frameBorder="0"></iframe>
      </div>
      <div class="container-image" v-if="_g_User.is_asistente_dif">
        <a href="https://www.bancobig.es/" target="_blank"><img
            src="/sites/all/themes/Porto_sub/img/banner-big.png"></a>
      </div>
    </div>
    <div v-else class="not-allowed-forum-container">
      <p class="not-allowed-forum-title"> SIN ACCESO </p>
      <p class="not-allowed-forum"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store"
          class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else
    style="text-align: center; height: 100%;">
    <div
      class="foro-container container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">
      <img class="foro-image"
        src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/foro.jpg">
      <div class="text-center">
        <h2>Foro</h2>
        <p>Punto de encuentro de todos los inversores de la comunidad Eurekers. ¿Tienes alguna duda que quieras plantear
          a los demás? ¿Te gustaría aportar alguna idea o sugerencia? Es muy gratificante poder interactuar con otras
          personas que comparten contigo la experiencia de invertir y que han recibido la misma formación. Además,
          contarás con la colaboración de los administradores web de Eurekers y de los profesores, quienes siempre están
          atentos a vuestros comentarios.</p>
      </div>
      <div class="button-container">
        <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
      </div>
    </div>
  </div>
</template>
<script>

import Button from '@/components/helpers/Button.vue';
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';
import { mapGetters } from "vuex";

export default {
  name: 'Foro',
  props: {
  },
  components: {
    Button,
    LoadingForum
  },
  data() {
    return {
      button_name: "Contenido reciente",
      button_key_recent: "button_comments",
      button_name2: "Foro",
      button_key_forum: "button_commentss",
      button_name3: "Busqueda de foro",
      button_key_search: "button_commentsss",
      active_button: 'contenidoreciente',

      tab_indexId: "contenidoreciente",
      foro_ob: null,
      contenido_ob: null,
      search_ob: null,
      iframe_forum_url: "",
      iframe_contenido_url: "",
      iframe_search_url: "",
      tab_obj: [
        {
          tab_name: "Contenido reciente",
          tab_id: "contenidoreciente"
        },
        {
          tab_name: "Foro",
          tab_id: "foro"
        },
        {
          tab_name: "Busqueda de foro",
          tab_id: "search"
        }
      ],
      isLoading: true,
      loadingMessage: "Cargando contenido reciente...",
    };
  },
  watch: {
    tab_indexId: {
      handler: function () {
        setTimeout(function (newVal, oldVal) {
          this._chargeIframe("");
        }.bind(this), 1);

      },
      deep: true
    },
    iframe_forum_url: {
      handler: function (newVal, olfVal) {
        if (newVal.includes("/node/") || newVal.includes('/stock/')) {
          //window.location.href = newVal;
        }
        else {
          this.addHashToLocation("forum_ref", newVal);
        }
      },
      deep: true
    },
    iframe_contenido_url: {
      handler: function (newVal, olfVal) {
        if (newVal.includes("/node/") || newVal.includes('/stock/')) {
          //window.location.href = newVal;
        }
        else {
          this.addHashToLocation("contenido_ref", newVal);
        }
      },
      deep: true
    },
    iframe_search_url: {
      handler: function (newVal, olfVal) {
        if (newVal.includes("/node/") || newVal.includes('/stock/')) {
          //window.location.href = newVal;
        }
        else {
          this.addHashToLocation("search_ref", newVal);
        }
      },
      deep: true
    },
  },
  created() {
  },
  mounted() {
    this.$refs.iframe.addEventListener('load', this.handleIframeLoad);

    if (this.$route.query.forum_ref != null && this.$route.query.forum_ref != undefined) {
      this.active_button = 'foro';
      this._chargeIframe(this.$route.query.forum_ref);
    }
    else if (this.$route.query.contenido_ref != null && this.$route.query.contenido_ref != undefined) {
      this._chargeIframe(this.$route.query.contenido_ref);
    }
    else if (this.$route.query.search_ref != null && this.$route.query.search_ref != undefined) {
      this._chargeIframe(this.$route.query.search_ref);
    }

    else {
      this._chargeIframe("");
    }
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged", "is_mobile", "_g_User", "_g_UserFeatures"]),
  },
  methods: {
    finishLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    _redirectToLogin() {
      window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
    },

    _reloadLastContent: function () {
      if (this.active_button == "contenidoreciente") {
        var iframe = document.getElementById('contenido-id');
        iframe.src = iframe.src + '';

      }
    },

    handleIframeLoad() {
      this.$refs.loader.finishLoading();
    },

    _forumIframeUpdated() {
      if(this.iframe_forum_url != "") {
        this.foro_ob = document.getElementById("foro-id");
        this.iframe_forum_url = this.foro_ob.contentWindow.location.href;
        this.finishLoading();
      }
    },
    _contenidoIframeUpdated() {
      if(this.iframe_contenido_url != "") {
        this.contenido_ob = document.getElementById("contenido-id");
        this.iframe_contenido_url = this.contenido_ob.contentWindow.location.href;
        this.finishLoading();
      }
    },
    _searchIframeUpdated() {
      if(this.iframe_search_url != "") {
        this.search_ob = document.getElementById("search-id");
        this.iframe_search_url = this.search_ob.contentWindow.location.href;
        this.finishLoading();
      }
    },
    addHashToLocation(key, val) {
      if (val != "") {
        if (key == "forum_ref") {
          history.replaceState(
            {},
            null,
            this.$route.path + '?forum_ref=' + val
          );
        }
        else if (key == "contenido_ref") {
          history.replaceState(
            {},
            null,
            this.$route.path + '?contenido_ref=' + val
          );
        }
        else if (key == "search_ref") {
          history.replaceState(
            {},
            null,
            this.$route.path + '?search_ref=' + val
          );
        }
      }
    },
    _getIndexTab(tab_id) {
      const activeToActive = this.tab_indexId === tab_id;
      this.isLoading = true;

      // Set loading message based on tab
      if (tab_id === 'contenidoreciente') {
        this.loadingMessage = "Cargando contenido reciente...";
      } else if (tab_id === 'foro') {
        this.loadingMessage = "Cargando foro...";
      } else if (tab_id === 'search') {
        this.loadingMessage = "Cargando búsqueda...";
      }

      this.tab_indexId = tab_id;
      this.active_button = tab_id;
      if (tab_id == 'contenidoreciente') {
        this._reloadLastContent();
      }
      if (activeToActive) {
        this._chargeIframe("");
      }
    },

    _chargeIframe: function (initial_url) {
      this.isLoading = true;
      this.contenido_ob = document.getElementById("contenido-id");
      this.foro_ob = document.getElementById("foro-id");
      this.search_ob = document.getElementById("search-id");

      var contenido_html_element_found = this.contenido_ob != null;
      var foro_html_element_found = this.foro_ob != null;
      var search_html_element_found = this.search_ob != null;

      if (this.tab_indexId == "foro") {
        if (initial_url != "") {
          this.iframe_forum_url = initial_url;
        }
        else {
          this.iframe_forum_url = '';
          this.iframe_forum_url = process.env.VUE_APP_API + '/forum';
        }
      }
      else if (this.tab_indexId == "contenidoreciente") {
        if (initial_url != "") {
          this.iframe_contenido_url = initial_url;
        }
        else {
          this.iframe_contenido_url = '';
          this.iframe_contenido_url = process.env.VUE_APP_API + '/contenido-reciente';
        }
      }
      else if (this.tab_indexId == "search") {
        if (initial_url != "") {
          this.iframe_search_url = initial_url;
        }
        else {
          this.iframe_search_url = '';
          this.iframe_search_url = process.env.VUE_APP_API + '/forum/search';
        }
      }

      if (foro_html_element_found) {
        this.foro_ob.src = this.iframe_forum_url;
      }
      if (contenido_html_element_found) {
        this.contenido_ob.src = this.iframe_contenido_url;
      }
      if (search_html_element_found) {
        this.search_ob.src = this.iframe_search_url;
      }
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 540px) {
  .main-container {
    margin-top: 30px;
    height: 100%;
  }
}

@media screen and (min-width: 540px) {
  .main-container {
    height: 100%;
  }
}

html {
  overflow: hidden;
}

.container-image {
  /* Aplica un margen automático para centrar el div horizontalmente */
  margin: 0 auto;
  /* Ajusta el ancho máximo del contenedor */
  max-width: 800px;
  max-height: auto;
  text-align: center;
}

.foro-image {
  height: 200px !important;
}

@media screen and (min-width: 768px) {
  .container-image img {
    width: 500px;
  }

  .container-image img {
    max-width: 500px;
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }
}

.text-center {
  text-align: center;
  margin: 20px;
}

.container-image p {
  text-align: justify;
}

.buttons-container {
  text-align: center;
  margin-bottom: 5px;
  z-index: 99;
}

#contenido-reciente {
  width: 300px;
  height: 40px;
  background: var(--color-surface);
  color: var(--color-text-primary);
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#contenido-reciente.active {
  width: 300px;
  height: 40px;
  border-radius: 0px;
  color: var(--color-text-primary);
  background-color: var(--color-highlight);
  border-bottom-color: #00add8;
  border-bottom-width: revert;
  border-bottom-style: solid;
}

#foro,
#search {
  width: 300px;
  height: 40px;
  background: var(--color-surface);
  color: var(--color-text-primary);
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#foro.active,
#search.active {
  width: 300px;
  height: 40px;
  border-radius: 0px;
  color: var(--color-text-primary);
  background-color: var(--color-highlight);
  border-bottom-color: #00add8;
  border-bottom-width: revert;
  border-bottom-style: solid;
}

.unauthorized {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 20px;
}

.login-button {
  color: white;
  background-color: #00add8;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  width: 150px;
  margin-top: 20px;
  font-size: 14px;
}

.login-button:hover {
  background-color: #00bdec;
}

.not-allowed-forum-container {
  text-align: center;
  margin-top: 50px;
}

.link-to-store {
  color: #35add8 !important;
}

.not-allowed-forum-title {
  color: #FF9B00;
  font-weight: bold;
}

.user-is-logged {
  height: 100%;
}

.loader-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  height: calc(100% - 45px);
  background-color: rgba(133, 133, 133, 0.413);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader-container img {
  width: 130px;
  height: 130px;
}

/* Initially hide the main content but keep it rendered */
.main-container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* When loading finishes, fade it in */
.loading-visible {
  opacity: 1;
}

/* Keep it hidden during loading */
.loading-hidden {
  opacity: 0;
}
</style>